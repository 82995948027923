import React, { useEffect, useRef, useState } from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from './slices/auth/login/reducer';
import { logoutUser } from './slices/thunks';
import { useNavigate } from 'react-router-dom';
import { fetchChainID, updateSigner } from './SmartContract';
import Swal from 'sweetalert2';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      console.log(accounts);
      if (accounts === undefined || accounts.length === 0) {
        dispatch(logoutUser());
      } else {
        console.log('MetaMask account changed:', accounts[0]);
        if (!isUserLogout) {
          updateSigner(accounts[0]);
          dispatch(logoutUser());
          dispatch(loginSuccess({ account: accounts[0] }));
          sessionStorage.setItem("authUser", accounts[0]);
          navigate("/dashboard");
        }
      }
    };

    const handleNetworkChanged = (networkId) => {
      console.log('MetaMask network changed:', networkId);
      if (networkId !== process.env.REACT_APP_NETWORK_ID && !isUserLogout) {
        Swal.fire({
          title: "The Network is wrong!",
          text: "Please Change Your Network to " + process.env.REACT_APP_NETWORK_NAME,
          icon: "warning",
          allowOutsideClick: false,
          showConfirmButton: false
        });
      } else if (networkId === process.env.REACT_APP_NETWORK_ID) {
        Swal.close();
        navigate("/dashboard");
      }
    };

    try {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('networkChanged', handleNetworkChanged);
      };
    } catch (e) {
      console.log("MetaMask extension not installed");
    }
  }, [isUserLogout, dispatch, navigate]);

  const initialCheckNetwork = async () => {
    if (!isUserLogout && await fetchChainID() !== process.env.REACT_APP_CHAIN_ID) {
      Swal.fire({
        title: "The Network is wrong",
        text: "Please Change Your Network to " + process.env.REACT_APP_NETWORK_NAME,
        icon: "warning",
        allowOutsideClick: false,
        showConfirmButton: false
      });
    }
    return null;
  };
  initialCheckNetwork();

  document.title = "DefyPay Sign In";

  const tawkMessengerRef = useRef();

  const onLoad = () => {
    console.log('onLoad works!');
    console.log(window.Tawk_API);
  };

  window.Tawk_API = window.Tawk_API || {};
  if (isUserLogout) {
    window.Tawk_API.autoStart = false;
  }

  useEffect(() => {
    console.log("window.Tawk_API)",window.Tawk_API)
    if (window.Tawk_API) {
      if (isUserLogout) {
        if (typeof window.Tawk_API.hideWidget === 'function') {
          window.Tawk_API.hideWidget();
          console.log("hidwidget");
        } else {
          console.log("hideWidget function not available");
        }
      } else {
        if (typeof window.Tawk_API.showWidget === 'function') {
          window.Tawk_API.showWidget();
          console.log("showWidget");
        } else {
          console.log("showWidget function not available");
        }
      }
    }
  }, [isUserLogout]);

  return (
    <React.Fragment>
      <Route />
      <TawkMessengerReact
        propertyId="669df0bb32dca6db2cb34ed7"
        widgetId="1i3cf8nr7"
        ref={tawkMessengerRef}
        onLoad={onLoad}
      />
    </React.Fragment>
  );
}

export default App;
                                                                                                                                                  