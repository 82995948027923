import React from "react";
import { Navigate } from "react-router-dom";

// Project
import ProjectList from "../pages/Projects/ProjectList";
import ProjectOverview from "../pages/Projects/ProjectOverview";
import CreateProject from "../pages/Projects/CreateProject";

//login
import Login from "../pages/Authentication/Login";

// User Profile
import CreateBatch from "../pages/Projects/CreateBatch";

const authProtectedRoutes = [
  { path: "/project-create", component: <CreateProject /> },
  { path: "/batch-create", component: <CreateBatch /> },
  { path: "/project-list", component: <ProjectList /> },
  { path: "/project-overview", component: <ProjectOverview /> },
  { path: "/dashboard", component: <ProjectList /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };