import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { contract } from '../../SmartContract';
//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import { useDispatch } from "react-redux";
import { logoutUser } from "../../slices/thunks";
import { performTransaction } from '../../pages/Projects/performTransaction';
import { testFunction } from '../../Testset/testcase';

const ProfileDropdown = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));

    const [userName, setUserName] = useState("undefined");
    const [userRole, setRole] = useState("undefined");

    useEffect(() => {
        console.log("updated user name & role")
        if (Object.keys(user).length > 0) {
            const fetchData = async () =>{
                try{
                    const result = await contract.userMap(user.account);
                    if(result)
                    {
                        setUserName(result[0]);
                        setRole(result[1]);
                    }
                }catch(e)
                {console.log("getUserName/Role Error:",e)}
            }
            fetchData();
        }
    }, [user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const handleLogout = () => {
        dispatch(logoutUser());
    }
    
    const handleViewTxn = (address) =>{
        const url = process.env.REACT_APP_TRANSACTIONS_VIEWER_LINK+address;
        window.open(url, "_blank");
    }

    const handleResetAll = () => {
        console.log("handleResetAll");
        performTransaction(null,"clearAllStorage");
    }
    
    const testCaseCount = process.env.REACT_APP_TEST_CASE_COUNT;
    const handleTestCase = (testCase) => {
        // Perform actions based on the test case
        console.log("handleTestCase");
        testFunction(testCase);
    }
    
    const buttonList = () =>{
        const buttons = [];
        for (let i = 0; i < testCaseCount; i++) {
            buttons.push(
                <button key={i} onClick={() => handleTestCase(i+1)} style={{ margin: "10px" }}>
                    Test case {i+1}
                </button>
            );
        }
        return <div>{buttons}</div>;
    }

    return (
        <React.Fragment>
            {buttonList()}
            <button onClick={()=>{handleResetAll()}}>Reset All</button>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1}
                            alt="Header Avatar" />
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-center">                  
                    <div className="dropdown-item">
                        <div className="d-flex flex-column align-items-start">
                            <div>
                                <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle">Wallet : <b>{user.account}</b></span>
                            </div>
                            <div>
                                <Icon path={mdiAccount} size={0.8} className="text-muted fs-16 align-middle me-1"></Icon><span
                                    className="align-middle">Name : <b>{userName}</b></span>
                            </div>
                            <div>
                                <i className="mdi mdi-nature-people text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle">Role : <b>{userRole}</b></span>
                            </div>                            
                            <Button className="btn btn-info" onClick={()=>handleViewTxn(process.env.REACT_APP_CONTRACT_ADDRESS)} style={{ 
                                width: '100%',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin:'10px 0px 5px'}}>Project Smart Contract</Button>
                            
                            <Button className="btn btn-info" onClick={()=>handleViewTxn(process.env.REACT_APP_DEFYPAY_CONTRACT_ADDRESS)} style={{ 
                                width: '100%',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin:'0px 0px 5px'}}>Transaction Smart Contract</Button>
                            <Button className="btn btn-info" onClick={()=>handleViewTxn(user.account)} style={{ 
                                width: '100%',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin:'0px 0px 5px'}}>User Transactions in Blockchain Network</Button>
                            <Button color="primary" onClick={handleLogout} style={{ 
                                width: '100%',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',}}>Logout</Button>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;